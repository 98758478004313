import crudActions from '@/libs/storeConstActions'
import store from '@/store'
import axios from '@/libs/axios'
import { stringify } from 'qs'

const endpoint = () => `customers/${store.state.customers?.customer?.id}/addresses`
const setDefault = (ctx, data) => axios.post(`/customers/addresses/${data.id}/set-default`)
const restoreAddress = (ctx, data) => axios.post('/customer/addresses/restore', data)
const checkFreeAddressType = (ctx, data) => axios.post('/customers/addresses/check-free-type', data)
const removeAddressType = (ctx, data) => axios.post('/customers/addresses/uncheck-type', data)

const getActiveAddressList = ({ commit }, queryParams) => new Promise((resolve, reject) => {
  // eslint-disable-next-line no-param-reassign
  queryParams = { ...queryParams, ...{ is_active: 1 } }
  axios.get(`customers/${store.state.customers?.customer?.id}/addresses`, {
    params: queryParams,
    paramsSerializer: params => stringify(params),
  })
    .then(response => {
      commit('LIST', response.data.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})
const getInActiveAddressList = ({ commit }, queryParams) => new Promise((resolve, reject) => {
  // eslint-disable-next-line no-param-reassign
  queryParams = { ...queryParams, ...{ is_active: 0 } }
  axios.get(`customers/${store.state.customers?.customer?.id}/addresses`, {
    params: queryParams,
    paramsSerializer: params => stringify(params),
  })
    .then(response => {
      commit('LIST', response.data.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

const setInActive = (ctx, data) => new Promise((resolve, reject) => {
  // eslint-disable-next-line no-param-reassign
  data.is_active = false
  axios.put(`customers/${store.state.customers?.customer?.id}/addresses/${data.id}`, data)
    .then(response => {
      resolve(response)
    }).catch(error => {
      console.log(error)
      reject(error)
    })
})
const setActive = (ctx, data) => new Promise((resolve, reject) => {
  // eslint-disable-next-line no-param-reassign
  data.is_active = true
  axios.put(`customers/${store.state.customers?.customer?.id}/addresses/${data.id}`, data)
    .then(response => {
      resolve(response)
    }).catch(error => {
      console.log(error)
      reject(error)
    })
})
const Actions = crudActions(endpoint)

export default {
  ...Actions,
  setDefault,
  setInActive,
  setActive,
  getActiveAddressList,
  getInActiveAddressList,
  restoreAddress,
  checkFreeAddressType,
  removeAddressType,
}
