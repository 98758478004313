export default {
  GET(state, data) {
    state.address = { ...data }
  },
  LIST(state, data) {
    state.addresses = data
  },
  SET_FORM(state, data) {
    state.addressForm = { ...data }
  },
}
